// eslint-disable-next-line import/no-cycle
import GetDataSync from './connectionManager';
import applicationEnv from './envManager';
import { toastStore } from '../context/globalStores';
import { showToastError } from '../context/actions/toastActions';
import { sessionStorageKeys } from './constant';

const env = await applicationEnv();
const { apiVersion } = env;
const ServiceUrl = {
  jsonData: `/offline/${apiVersion}/jsonData`,
  login: `/offline/${apiVersion}/login`,
  refreshToken: `/offline/${apiVersion}/refresh-token`,
  productCategory: `/offline/${apiVersion}/productCategory`,
  parameter: `/offline/${apiVersion}/parameter/`,
  merchants: `/offline/${apiVersion}/merchants?isStore=`,
  merchantWithRelation: `/offline/${apiVersion}/merchants-with-relations`,
  merchantCount: `/offline/${apiVersion}/merchant/count`,
  bankCount: `/offline/${apiVersion}/bank/count`,
  creditSummary: `/offline/${apiVersion}/credit-summary`,
  merchantCreditSummary: `/offline/${apiVersion}/merchant-credit-summary?guid=`,
  getBanks: `/offline/${apiVersion}/banks`,
  searchAllMerchants: `/offline/${apiVersion}/order?userGuid=`,
  getMerchant: `/offline/${apiVersion}/merchant?userGuid=`,
  orderCounts: `/offline/${apiVersion}/order-counts?userGuid=`,
  storeCount: `/offline/${apiVersion}/store/count?userGuid=`,
  getSuperMerchants: `/offline/${apiVersion}/merchant/branch?userGuid=`,
  orderCountsByMonth: `/offline/${apiVersion}/order-counts-by-month?userGuid=`,
  refunds: `/OnlineShoppingLoan/${apiVersion}/refunds`,
  sms: `/sms/${apiVersion}/sendSms`,
  sendOtp: `/sms/${apiVersion}/sendOtp`,
  otpValidation: `/sms/${apiVersion}/otpValidation`,
  partner: `/offline/${apiVersion}/partner`,
  order: `/offline/${apiVersion}/order`,
};

const jsonData = ({
  totalAmount,
  shippingPrice,
  expireDate,
  currency = '949',
  orderNumber,
  successRedirectUrl,
  failureRedirectUrl,
  campaignNumber,
  cart,
  email,
  phoneNumber,
  nationalIdentityNumber,
  address,
  fullName,
  isOffline,
}) => {
  const merchantId = sessionStorage.getItem(sessionStorageKeys.merchantId);
  const endpoint = ServiceUrl.jsonData;
  const body = {
    merchantId,
    totalAmount,
    shippingPrice,
    expireDate,
    currency,
    orderNumber,
    successRedirectUrl,
    failureRedirectUrl,
    campaignNumber,
    cart,
    isOffline,
    customerInfo: {
      email,
      phoneNumber,
      nationalIdentityNumber,
      address,
      fullName,
    },
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      sendMerchant: true,
      sendSession: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const login = ({
  username, password,
}) => {
  const endpoint = ServiceUrl.login;
  const body = {
    username,
    password,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        toastStore.dispatch(showToastError({ text: err?.messages[0] }));
      });
  });
};

const productCategory = () => {
  const endpoint = ServiceUrl.productCategory;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const refunds = async ({
  bankCode,
  amount,
  orderNumber,
}) => {
  const endpoint = ServiceUrl.refunds;
  const body = {
    bankCode,
    amount,
    orderNumber,
  };
  const env = await applicationEnv();
  const { token } = env;
  const header = { apikey: `${token}` };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      header,
      sendMerchant: true,
      sendSession: true,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const sms = ({
  gsm,
  redirectionUrl,
}) => {
  const endpoint = ServiceUrl.sms;
  const body = {
    gsm,
    redirectionUrl,
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const e = new Error();
      e.message = 'otpTimeout';
      reject(e);
    }, 5000);
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sendOtp = ({
  merchantId,
  orderId,
  gsm,
}) => {
  const endpoint = ServiceUrl.sendOtp;
  const body = {
    merchantId,
    orderId,
    gsm,
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const e = new Error();
      e.message = 'otpTimeout';
      reject(e);
    }, 5000);
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const otpValidation = ({
  merchantId,
  orderId,
  userId,
  gsm,
  otpCode,
}) => {
  const endpoint = ServiceUrl.otpValidation;
  const body = {
    merchantId,
    orderId,
    userId,
    gsm,
    otpCode,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err?.resultMessage.toLowerCase().includes('otp')) {
          toastStore.dispatch(showToastError({ text: err.resultMessage }));
        }
        reject(err);
      });
  });
};

const merchants = (isStore = false) => {
  const endpoint = ServiceUrl.merchants + String(isStore);
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getParameter = ({ parameter }) => {
  const endpoint = ServiceUrl.parameter + parameter;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const merchantWithRelation = () => {
  const endpoint = ServiceUrl.merchantWithRelation;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const searchAllMerchants = ({
  startDate,
  endDate,
  orderId,
  applicationStatusCode,
  merchants,
}) => {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.searchAllMerchants + userGuid;
  const body = {
    startDate,
    endDate,
    orderId,
    applicationStatusCode,
    merchants,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      fileLoading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
function getMerchant({ userGuid }) {
  const endpoint = ServiceUrl.getMerchant + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function orderCounts(startDate, endDate) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.orderCounts + userGuid}${startDate ? `&startDate=${startDate.split('T')[0]}` : ''}${endDate ? `&endDate=${endDate.split('T')[0]}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function storeCount() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.storeCount + userGuid}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getSuperMerchants() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.getSuperMerchants + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function orderCountsByMonth() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.orderCountsByMonth + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function merchantCount() {
  const endpoint = ServiceUrl.merchantCount;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function bankCount() {
  const endpoint = ServiceUrl.bankCount;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getBanks() {
  const endpoint = ServiceUrl.getBanks;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function creditSummary({ bankId, merchantId }) {
  const endpoint = `${ServiceUrl.creditSummary}${bankId ? `?bankId=${bankId}` : '?'}${merchantId ? `merchantId=${merchantId}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function merchantCreditSummary({ storeId }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.merchantCreditSummary}${userGuid}${storeId ? `&storeId=${storeId}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function partner({ data }) {
  const endpoint = ServiceUrl.partner;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getOrderList({
  orderId,
  applicationStatusCodes,
  startDate,
  endDate,
  merchants,
  banks,
  integrationTypeId,
  creditType,
  page,
  size,
}) {
  const body = {
    orderId,
    applicationStatusCodes,
    startDate,
    endDate,
    merchants,
    banks,
    integrationTypeId,
    creditType,
  };
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.order}?userGuid=${userGuid}&page=${page}&size=${size}&sort=updatedDate,DESC`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export {
  login,
  productCategory,
  refunds,
  sendOtp,
  sms,
  otpValidation,
  merchants,
  searchAllMerchants,
  getMerchant,
  jsonData,
  getSuperMerchants,
  ServiceUrl,
  merchantCount,
  bankCount,
  creditSummary,
  partner,
  getBanks,
  orderCounts,
  getOrderList,
  merchantWithRelation,
  getParameter,
  merchantCreditSummary,
  orderCountsByMonth,
  storeCount,
};

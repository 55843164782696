import React, { useEffect, useRef, useState } from 'react';
import styles from './header.module.css';
import logo from '../../../assets/images/header/fingate_logo_white_1.svg';
import logOut from '../../../assets/images/new/log_out_1.svg';
import person from '../../../assets/images/header/Icon.svg';
import { clear } from '../../../helpers/sessionManager';
import { sessionStorageKeys } from '../../../helpers/constant';

function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  function signOut() {
    clear();
  }
  const userName = sessionStorage.getItem(sessionStorageKeys.userName);
  const merchantNameAlias = sessionStorage.getItem(sessionStorageKeys.merchantNameAlias);
  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      window.addEventListener('click', (evt) => {
        setOpenMenu(false);
      }, false);
    }
    currentRef.current = false;
  }, []);
  function handleClick() {
    setTimeout(() => {
      setOpenMenu(!openMenu);
    }, 50);
  }

  return (
    <div className={styles.main}>
      <img alt="fingate" src={logo} className="m-l-64" />
      <div className="d-flex p-relative">
        <div
          className={`${styles.person} c-pointer`}
          onClick={() => {
            handleClick();
          }}
        >
          <div className="d-flex f-column m-r-16">
            <div>{userName}</div>
            <div className={styles.merchantNameAlias}>{merchantNameAlias}</div>
          </div>
          <img alt="person" src={person} />
        </div>
        {openMenu && (
        <div className={styles.exit}>
          <div onClick={() => signOut()} className="p-10">
            Çıkış
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export default Header;
